@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&family=Teko:wght@400;500;600&display=swap');

:root {
  --theme-color: #ec1668;
  --theme-black: #2e0414;
  --theme-color-hover: #fc3581;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--theme-color);
  color: #ffffff;
}

a {
  color: var(--theme-color);
}

.background {
  background-color: var(--theme-black);
  background-image: url('../public/images/grid_bkg.svg');
  background-position: 50% 50%;
  background-repeat: repeat;
}

.numbers {
  font-family: 'Teko', sans-serif;
  line-height: 2rem;
}



::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background-color: var(--theme-black);
}
::-webkit-scrollbar-thumb {
  background-color: var(--theme-color);
}