.notificationsList {
  
}

.notificationItem {
  cursor: pointer;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0,0,0,0.15);
  padding: 1rem;
  border-radius: 0.5rem;

  transition: 0.2s ease background-color, 0.1s ease color;
}

.notificationItem:hover {
  background-color: var(--theme-color);
  color: var(--theme-black);
}

.message {
  opacity: 1;
}

.handleHover {
  position: absolute;

  margin: 1rem;

  display: none;
  opacity: 0;

  transition: 0.1s ease opacity;
}

.notificationItem:hover .handleHover {
  display: block;
  opacity: 1;
}

.notificationItem:hover .message {
  opacity: 0;
}