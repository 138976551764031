.playerCards {
  perspective: 500px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;
  gap: 1rem;

  padding: 3rem 0;

  overflow-y: auto;

  z-index: 16;
}

.flag {
  position: relative !important;
  width: 24px;
  filter: none !important;
}

.playerCard {
  cursor: pointer;
  position: relative;
  width: 240px;
  height: 280px;
}
.playerCard:hover {
  animation: liftAlongZFromCorner 0.5s ease-in-out forwards;
}
.playerCard img {
  position: absolute;
  top: 0;
  display: block;
  
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4))
}
.playerCardInfo {
  position: absolute;
  top: 30px;
  left: 50px;
}

.statChart {
  position: absolute;
  top: 20px;
  right: 5px;
  width: 100%;
  height: 100%;
  text-align: right;
  font-size: 1.25rem;
}
.statValue {
  width: 30px;
}

.front {
  opacity: 0;
}

.revealed > .front {
  animation: frontFlip 0.5s ease-in-out forwards;
}
.revealed > .back {
  animation: backFlip 0.5s ease-in-out forwards;
}

.drop {
  animation: fadeAndDropIn 0.5s ease-in-out;
}

@keyframes fadeAndDropIn {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes liftAlongZFromCorner {
  0% {
    transform: translateZ(0);
    transform: rotateX(0deg) rotateY(0deg);
  }
  30% {
    transform: rotateX(10deg) rotateY(7deg) translateZ(18px);
  }
  40% {
    transform: rotateX(8deg) rotateY(10deg) translateZ(24px);
  }
  100% {
    transform: rotateX(0deg) rotateY(0deg) translateZ(60px);
  }
}

@keyframes frontFlip {
  0% {
    transform: rotateZ(0deg) rotateY(180deg);
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    transform: rotateZ(10deg) rotateY(90deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(0deg) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes backFlip {
  0% {
    transform: rotateZ(0deg) rotateY(0deg);
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    transform: rotateZ(-10deg) rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: rotateZ(0deg) rotateY(180deg);
    opacity: 0;
  }
}