.layout {
  background-color: var(--theme-black);
  background-image: url('../../public/images/grid_bkg.svg');
  background-position: 50% 50%;
  background-repeat: repeat;
  min-height: 100vh;
}

.layoutLogo {
  cursor: pointer;
  width: 100px;
  padding: 0.5rem;
}

.layoutNavMenuButton {
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.layoutNavMenuLink {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;

  font-size: 1.5rem;

  transition: background-color 0.2s ease-in-out;
}

.layoutNavMenuLink:hover {
  background-color: var(--theme-black);
}

.saihlNotificationsList {
  background-color: var(--theme-black);
}