.teamOverview {
  user-select: none;
  padding: 1rem;
  max-width: 650px;
  margin: 0 auto 1rem auto;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}
.overviewButtons {
  box-sizing: border-box;
  max-width: 650px;
  margin: 1rem auto;
}
.overviewRecord {
  margin: -0.5rem 0 0 0.5rem;
}
.overviewRecord .label {
  font-size: 0.8rem;
}
.overviewRecord .record {
  font-size: 1.5rem;
}

.managementLink {
  cursor: pointer;
  user-select: none;
  width: 30%;
  padding: 1rem 0;
  background-color: rgba(26, 3, 13, 0.75);

  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.5rem;
  text-transform: uppercase;

  transition: background-color 0.2s ease-in-out;
}
.managementLink:hover {
  background-color: rgba(26, 3, 13, 0.4);
}
.managementLinkInside {
  padding: 1rem;
  width: 100%;
}


@media (max-width: 650px) {
  .teamOverview {
    width: 100%;
  }
  .overviewButtons {
    width: 100%;
    overflow: hidden;
    padding: 0 0.5rem;
    margin: 0;
  }
  .managementLink {
    font-size: 1rem;
    padding: 0.5rem 0;
  }
}