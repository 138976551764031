.openPacks {
  min-height: 100vh;
  padding: 1rem;
}


.openPackWrapper {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 30;
}

.darkenScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 12;
}

.closeButton {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  padding: 1rem;
}

@keyframes scaleToCenter {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1.75);
  }
}

/*
  Next step: Open a pack
  - Click on a pack
  - Animate the pack to the center of the screen
  - Darken the screen
  - Have the pack glow and hide the screen with the light
  - Load the cards in the pack
  - Reveal the cards by fading the glow
*/