.rosterPlayer {
  cursor: pointer;

  min-height: 38px;
  padding: 0.25rem;
  display: grid;
  grid-template-columns: 0.15fr 0.15fr 1.1fr 0.25fr 0.25fr 0.15fr repeat(7, 0.25fr) 0.5fr 0.5fr;

  align-items: center;

  transition: background-color 0.2s ease;
}
.rosterPlayer:hover {
  background-color: rgba(0,0,0,0.25);
}

.ManageRosterHeader {
  padding: 0.25rem;
  display: grid;
  grid-template-columns: 0.15fr 0.15fr 1.1fr 0.25fr 0.25fr 0.15fr repeat(7, 0.25fr) 0.5fr 0.5fr;
  align-items: center;
}

.highlighted {
  color: var(--theme-color);
}

.centered {
  text-align: center;
}

.ManageRosterHeaderItem {
  cursor: pointer;
}

.setLines {
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lineText{
  writing-mode: vertical-lr;
  text-orientation: mixed;
  text-transform: uppercase;
  text-align: center;
  height: 60px;
}
.linePlayer {
  cursor: pointer;

  width: 100px;
  height: 60px;
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 0.25rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0,0,0,0.25);

  margin-bottom: 0.5rem;

  transition: background-color 0.2s ease;
}
.linePlayer:hover {
  background-color: rgba(0,0,0,0.4);
}

.playerLineOption {
  cursor: pointer;
  padding: 0.3rem 0.5rem;

  border-bottom: solid 1px rgba(0,0,0,0.2);
  border-radius: 0.25rem;

  transition: background-color 0.2s ease;;
}
.playerLineOption.activePlayer {
  color: var(--theme-color);
}

.playerLineOption:hover {
  background-color: rgba(0,0,0,0.2);
}

.bioTopOverview {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.bioStats {
  justify-content: flex-start;
  align-items: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  text-align: center;
  gap: 1rem;
}

.verticalBar {
  border-left: solid 1px rgba(255, 255, 255, 0.2);
  height: 40px;
}

.hideNarrow {
  display: inherit;
}

.bioFlag {
  width: 60px;
  margin-left: 3rem;
}
.bioNationality {
  line-height: 1rem;
  text-align: center;
}

.bioWrapper {
  padding: 1.5rem 1rem;
  text-align: left;
}

.playerType {
  margin-left: 1rem;
  text-transform: uppercase;
}
.playerRole {
  margin-right: 1rem;
}
.playerTypeSeperator {
  border-right: solid 1px rgba(255, 255, 255, 0.2);
  height: 30px;
  width: 1px;
}

.ratingDetailWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.ratingDetails {
  justify-content: flex-start;
}

.ratingGroup {
  width: 30%;
  min-width: 300px;
}
.rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  width: 100%;
}
.ratingHeader {
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
}
.ratingValue {
  margin-left: auto;
  font-size: 1.25rem;
  margin-top: 0.25rem;
}
.substat {
  text-transform: capitalize;
  width: 80px;
}
.manageRosterTopInfo {
  text-align: left;
  margin-left: 1rem;
}

@media (max-width: 600px) {
  .manageRosterTopInfo {
    text-align: center;
    margin: 0 0 1rem 0;
  }
  .ratingDetailWrapper {
    flex-direction: column;
  }
  .ratingDetails {
    justify-content: center;
  }

  .bioTopOverview {
    flex-direction: column;
    gap: 0;
  }

  .bioFlag {
    margin-left: 1rem;
  }

  .bioStats {
    justify-content: space-between;
  }

  .rosterPlayer {
    grid-template-columns: 0.15fr 1fr 0.4fr 0.25fr 0.5fr 0.5fr;
    padding: 0.5rem 0.25rem;
    font-size: 1.2rem;
  }

  .ManageRosterHeader {
    grid-template-columns: 0.15fr 1fr 0.4fr 0.25fr 0.5fr 0.5fr;
  }

  .hideNarrow {
    display: none;
  }
}