.activeMatch {
  cursor: pointer;
  user-select: none;

  background-color: var(--theme-color);
  text-align: center;
  padding: 0.5rem;
  width: 100%;
  max-width: 650px;
  margin:  0 auto 0.5rem auto;
}

@media (max-width: 650px) {
  .activeMatch {
    box-sizing: border-box;
    width: 96%;
    margin: 0 2%;
  }
}