.launchWrapper {
  position: fixed;
  top: 0;

  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.launcher {
  position: relative;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  overflow: hidden;

  animation: fadeOut 1s ease-in;
  animation-fill-mode: forwards;
  animation-delay: 5.5s;
}

.blackBackground {
  background-color: var(--theme-black);

  width: 100%;
  height: 100%;
}

.logoSlide {
  position: fixed;
  top: 0;

  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoSlide img svg path {
  transform-origin: center;
}
.logoSlide img {
  position: absolute;
  opacity: 0;
  transform: scale(0.7);
  animation: swipe-reveal-logo 10s ease-in;
  animation-delay: 4s;
}

.logoBounce {
  position: fixed;
  top: 0;

  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoBounce img svg path {
  transform-origin: center;
}
.logoBounce img {
  position: absolute;
  transform: scale(0);
  animation: s-reveal-slide 4s ease-in;
  animation-delay: 3s;
}

.outlinePulse {
  position: fixed;
  top: 0;

  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.outlinePulse img svg path {
  transform-origin: center;
}
.outlinePulse img {
  position: absolute;
  transform: scale(0);
  animation: scale-outline-letter 1.25s ease-in;
  animation-delay: 2s;
}
.outlinePulse img:nth-child(1) { animation-delay: 2.25s }
.outlinePulse img:nth-child(2) { animation-delay: 2.5s }
.outlinePulse img:nth-child(3) { animation-delay: 2.75s }
.outlinePulse img:nth-child(4) { animation-delay: 3s }

.scaleCard {
  position: fixed;
  top: 0;

  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.scaleCard img {
  position: absolute;
  transform: scale(0);
  animation: scale-card 12s ease-in;
}
.scaleCard img svg path {
  transform-origin: center;
}

@keyframes scale-card {
  0% {
    transform: scale(0);
  }
  5% {
    transform: scale(0);
  }
  10% {
    transform: scale(4) rotate(90deg);
  }
  40% {
    transform: scale(4) translateY(-5000px);
    opacity: 1;
  }
  100% {
    transform: scale(4) translateY(-5000px);
    opacity: 0;
  }
}

@keyframes scale-outline-letter {
  5% {
    transform: scale(0);
  }
  100% {
    transform: scale(60);
  }
}

@keyframes s-reveal-slide {
  5% {
    transform: scale(0);
  }
  15% {
    transform: scale(0.26);
  }
  20% {
    transform: scale(0.22) translateX(0%);
  }
  40% {
    transform: scale(0.22) translateX(-110%);
  }
  100% {
    transform: scale(0.22) translateX(-110%);
  }
}

@keyframes swipe-reveal-logo {
  5% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}