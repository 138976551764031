img svg path {
  transform-origin: center;
}

.cardPackWrapper {
  position: relative;
  z-index: 15;
  max-width: 300px;
}
.displayCardPackWrapper {
  position: relative;
}

.scaleUp * {
  animation: none !important;
}

.cardPack {
  position: relative;
  cursor: pointer;
  perspective: 400px;
  padding: 1rem 0;
  
  z-index: 1;
  
  max-width: 300px;
  max-height: 300px;
}
.cardPack:hover {
  transform: rotateZ(0deg);
  animation: shake-once 1.5s linear infinite;
}
.cardPack img {
  display: block;
  max-height: 300px;
  margin: 0 auto;
  transform: translateY(0px);
  filter:
    drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4))
    drop-shadow(0px 0px 1px rgba(236, 22, 104, 1)); 
  
  pointer-events: none;
}

.opening {
  margin-left: auto;
  margin-right: auto;
  animation: scaleOpen 1.5s ease-in-out forwards;
}
.jitter {
  animation: jitter 0.5s ease-in-out infinite;
}
.float {
  animation: float 10s ease-in-out infinite;
}
.shake {
  animation: shake 6s linear infinite;
}
.shakeOnce {
  animation: shake-once 1.5s linear infinite;
}
.yaw {
  animation: yaw 14s linear infinite;
}
.sss-glow {
  animation: sss-glow 2s ease-in-out infinite;
}
.ss-glow {
  animation: ss-glow 3s ease-in-out infinite;
}


.countLocation {
  position: absolute;
  bottom: 20px;
  right: 50px;
  font-size: 2rem;
  font-weight: bold;
}

.underShadow {
  z-index: 0;
  width: 200px;
  margin: 0.5rem 50px 0 50px;
}

@keyframes scaleOpen {
  0% {
    transform: scale(1.25);
  }
  90% {
    transform: scale(1);
  }
  95% {
    opacity: 1;
  }
  100% {
    transform: scale(12);
    opacity: 0;
  }
}

@keyframes jitter {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    transform: rotateZ(0deg);
  }
  18% {
    transform: rotateZ(0deg);
  }
  20% {
    transform: rotateZ(2deg);
  }
  22% {
    transform: rotateZ(3deg);
  }
  23% {
    transform: rotateZ(2deg);
  }
  24% {
    transform: rotateZ(3deg);
  }
  26% {
    transform: rotateZ(2deg);
  }
  28% {
    transform: rotateZ(0deg);
  }
  68% {
    transform: rotateZ(0deg);
  }
  70% {
    transform: rotateZ(-2deg);
  }
  73% {
    transform: rotateZ(-3deg);
  }
  74% {
    transform: rotateZ(-2deg);
  }
  75% {
    transform: rotateZ(-3deg);
  }
  77% {
    transform: rotateZ(-2deg);
  }
  79% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@keyframes shake-once {
  0% {
    transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(0deg);
  }
  15% {
    transform: rotateZ(2deg);
  }
  20% {
    transform: rotateZ(4deg);
  }
  22% {
    transform: rotateZ(2deg);
  }
  30% {
    transform: rotateZ(4deg);
  }
  35% {
    transform: rotateZ(2deg);
  }
  40% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes yaw {
  0% {
    transform: rotateY(0deg);
  }
  33% {
    transform: rotateY(-20deg);
  }
  66% {
    transform: rotateY(20deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes sss-glow {
  0% {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4)) drop-shadow(0px 0px 20px rgb(236, 22, 104)); 
  }
  50% {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4)) drop-shadow(0px 0px 50px rgb(236, 22, 104)); 
  }
  100% {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4)) drop-shadow(0px 0px 20px rgb(236, 22, 104)); 
  }
}

@keyframes ss-glow {
  0% {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4)) drop-shadow(0px 0px 10px rgb(184, 17, 81)); 
  }
  50% {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4)) drop-shadow(0px 0px 20px rgb(184, 17, 81)); 
  }
  100% {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4)) drop-shadow(0px 0px 10px rgb(184, 17, 81)); 
  }
}
