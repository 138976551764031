.transitionWrapper {
  z-index: 450;
  position: fixed;
  top: 0;

  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.transitionOut {
  position: relative;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  overflow: hidden;
}

.scaleCardOut, .scaleCardIn {
  position: fixed;
  top: 0;

  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.scaleCardOut img {
  z-index: 440;
  position: absolute;
  transform: scale(0);
  animation: scale-card-out 1s ease-in forwards;
}
.scaleCardIn img {
  z-index: 440;
  position: absolute;
  width: 400%;
  height: 400%;
  transform: scale(8) rotate(120deg);
  animation: scale-card-in 1.5s ease-in forwards;
}
.scaleCardOut img svg path, .scaleCardIn img svg path {
  transform-origin: top left;
}

@keyframes scale-card-out {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(4) rotate(90deg);
  }
  100% {
    transform: scale(4);
  }
}

@keyframes scale-card-in {
  0% {
    transform: translateY(0) rotate(120deg);
  }
  20% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(4000px) rotate(120deg);
  }
}