.landing {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}
.logo {
  opacity: 0;
  width: 250px;
  padding: 0.5rem;
  animation: slideInTop 0.3s ease-in-out forwards, fadeIn 0.2s ease-in-out forwards;
  animation-delay: 0.5s;
}

.buttonTransition {
  opacity: 0;
  animation: slideInBottom 0.3s ease-in-out forwards, fadeIn 0.2s ease-in-out forwards;
  animation-delay: 0.5s;
}
.fadeInText {
  opacity: 0;
  animation: fadeIn 0.2s ease-in-out forwards;
  animation-delay: 0.5s;
}
.loginLink {
  cursor: pointer;
  padding: 0.5rem;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 1.5s;
  color: var(--theme-color);

  transition: color 0.2s ease-in-out;
}
.loginLink:hover {
  color: var(--theme-color-hover);
}

@keyframes slideInTop {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInBottom {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}