.highlighted {
  color: var(--theme-color);
}

.centered {
  text-align: center;
}

.freeAgentPlayer {
  cursor: pointer;

  padding: 0.25rem;
  display: grid;
  grid-template-columns: 0.1fr 1fr repeat(14, 0.25fr);
  gap: 0.5rem;
  align-items: center;

  transition: background-color 0.2s ease;
}
.freeAgentPlayer:hover {
  background-color: rgba(0,0,0,0.25);
}

.FreeAgentsHeader {
  padding: 0.25rem;
  display: grid;
  grid-template-columns: 0.1fr 1fr repeat(14, 0.25fr);
  gap: 0.5rem;
  align-items: center;
}

@media (max-width: 600px) {
  .freeAgentPlayer {
    grid-template-columns: 100px repeat(6, 0.25fr);
  }

  .FreeAgentsHeader {
    grid-template-columns: 100px repeat(6, 0.25fr);
  }

  .hideNarrow {
    display: none;
  }
}